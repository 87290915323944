<script>

    import { createEventDispatcher } from 'svelte'
    import { fade } from 'svelte/transition'
    import { toast } from 'bulma-toast'
    import { popupStore } from '../stores'

    import PackagesService from '../$services/packages.service'
    import Volumetric from '../$methods/volumetric.methods'

    import Spinner from '../$components/spinner.svelte'
    import Switch from '../$components/switch.svelte'
    import InputText from '../$components/input.text.svelte'
    import InputNumber from '../$components/input.number.svelte'
    import Select from '../$components/select.svelte'

    const dispatch = createEventDispatcher()

    export let ipackage = {}
    export let showButtons = true
    export let showSwitchOrder = false
    
    let packages = []
    let selected = null
    let loading = false
    let addInsurance = !!ipackage.value

    const envelope = {
        _id: '1234',
        alias: 'Sobre/Paquete 1kg',
        width: 20,
        height: 5,
        length: 30,
        weight: 1,
        value: 0,
        content: 'Descripción del paquete'
    }

    getPackages()

    async function getPackages() {

        loading = true
        const resp = await PackagesService.getPackages()
        loading = false

        if(!resp.success)
            return toast({message: resp.error.message, type: 'is-danger'})

        packages = [envelope, ...resp.data]

        if(!ipackage.width)
            ipackage = packages.find(item => item.default) || {}

        selected = ipackage? ipackage._id : ''
    }

    function setPackage() {
        
        ipackage = packages.find(item => item._id === selected) || {}

        if(addInsurance === false)
            ipackage.value = 0
    }

    function onSelected() {
        dispatch('selected', ipackage)
    }
 
</script>

<Spinner {loading} />

{#if !loading}
    <div class="container">
        <div class="columns">
            <div class="column">
                <form on:submit|preventDefault={onSelected} in:fade>
                    {#if packages.length}
                        <div class="columns">
                            <div class="column">
                                <Select bind:value={ selected } on:change={setPackage} label="Selecciona un paquete" icon="list" required={false} options={
                                        packages.map( item => {
                                            return { value: item._id, text: item.alias }
                                        })
                                    }
                                />
                            </div>
                        </div>
                    {/if}
                    {#if ipackage}
                        <div class="columns" style="padding-top: 8px;">
                            <div class="column">
                                <InputNumber bind:value={ ipackage.width } label="Ancho" icon="ruler-horizontal" placeholder="Ancho, medida en cm" required/>
                            </div>
                            <div class="column">
                                <InputNumber bind:value={ ipackage.height } label="Alto" icon="ruler-vertical" placeholder="Alto, medida en cm" required/>
                            </div>
                            <div class="column">
                                <InputNumber bind:value={ ipackage.length } label="Largo" icon="ruler" placeholder="Largo, medida en cm" required/>
                            </div>
                            <div class="column">
                                <InputNumber bind:value={ ipackage.weight } label="Peso" icon="weight" placeholder="Peso en kg" required/>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="items-end">
                                    <span class="icon-text">
                                        <span>Peso volumétrico <b>{Volumetric(ipackage)} Kg</b> </span>
                                        <span on:click={() => popupStore.set('volumetric_popup')} class="class-icon"><i class="fas fa-question-circle" style="color: #E66985; cursor: pointer;"></i></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="columns">
                            <div class="column is-4">
                                <Switch bind:checked={ addInsurance } on:change={() =>  addInsurance === false? (ipackage.value = 0) : null } label="Asegurar paquete" />
                            </div>
                            {#if addInsurance}
                                <div class="column">
                                    <InputNumber bind:value={ ipackage.value } label="Valor del paquete" icon="dollar-sign" placeholder="Valor declarado" required/>
                                </div>
                            {/if}
                        </div>
                        {#if showSwitchOrder}
                            <div class="columns">
                                <div class="column">
                                    <div class="field">
                                        <Switch bind:checked={ipackage.skipOrder} label="No usar las medidas guardadas en la orden" />
                                    </div>
                                </div>
                            </div>
                        {/if}
                        {#if showButtons}
                            <div class="items-end">
                                <button class="button is-primary is-fullwidth">
                                    <span class="icon">
                                        <i class="fas fa-arrow-alt-circle-right"></i>
                                    </span>
                                    <span>Continuar</span>
                                </button>
                            </div>
                        {/if}
                    {/if}
                </form>
            </div>
        </div>
    </div>
{/if}