<script>

    import { configStore } from '../stores'

    import LocationsZipcodesComponent from '../locations/component.zipcodes.svelte'
    import PackagesSelectInputsComponent from '../packages/component.select.svelte'
    import QuotationsComponent from '../quotations/component.quotations.svelte'
    import LocationsComponent from '../locations/component.select.svelte'
    import ShipmentComponent from '../orders/component.shipment.create.svelte'
    import PositionsComponent from '../locations/component.positions.svelte'

    import Container from '../$layouts/container.layout.svelte'
    import Switch from '../$components/switch.svelte'

    import SwitchQuotation from '../$components/select.shipment.type.svelte'
    import ZipcodesInternational from '../locations/component.zipcode.international.svelte'

    import VolumetricPopup from '../$popups/popup.volumetric.weight.svelte'
    import ValuePopup from '../$popups/popup.declared.value.svelte'
    import FrequencyPopup from '../$popups/popup.delivery.frequency.svelte'
    import IdentifierPopup from '../$popups/popup.personal.identifier.svelte'

    import Menu from '../$layouts/menu.layout.svelte'

    let pickup
    let delivery
    let ipackage
    let quotation
    let localServices
    let step = 'zipcodes'
    let shipmentType = 'national'

    function onPackageSelected() {

        if(!pickup.zipcode || !delivery.zipcode)
            return

        if(shipmentType === 'international')
            if(!pickup.country || !delivery.country)
                if(!pickup.state || !delivery.state)
                    if(!pickup.city || !delivery.city)
                        return

        step = localServices? 'positions' : 'quotations'
    }

    function onPositionsSelected() {
        step = 'quotations'
    }

    function onQuotationSelected() {

        if($configStore)
            pickup.sendEmail = $configStore && $configStore.email? $configStore.email.sendPickup : false

        step = 'pickup'
    }

    function onPickupSelected() {

        if($configStore)
            delivery.sendEmail = $configStore && $configStore.email? $configStore.email.sendDelivery : false

        step = 'delivery'
    }

    function onDeliverySelected() {
        step = 'guide'
    }

    function setStep(event) {
        step = event.detail || event
    }  

    function clearLocations() {
        pickup = {}
        delivery = {}
    }

</script>

<style>
    .steps {
        margin-left: 15%;
        margin-right: 15%;
        margin-bottom: 0;
    }
    
    .column.is-11 .steps{
        margin-left: 7%;
        margin-right: 16%;
        margin-bottom: 0%
    }

    @media (max-width: 400px) {
        ul.steps {
            display: none;
        }
    }
</style>

<Menu />

<Container>
    <div class="columns">
        <div class="column is-1">
            <div class="items-center">
                {#if step === 'quotations'}
                    <span on:click={() => setStep('zipcodes')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'pickup'}
                    <span on:click={() => setStep('quotations')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'delivery'}
                    <span on:click={() => setStep('pickup')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
                {#if step === 'guide'}
                    <span on:click={() => setStep('delivery')} class="icon is-medium pointer"><i class="fas fa-2x fa-arrow-left"></i></span>
                {/if}
            </div>
        </div>
        <div class="column is-11">
            <ul class="steps has-content-centered">
                <li class="steps-segment" class:is-active={step === 'zipcodes'}>
                    <span class="steps-marker">
                        <img src="images/febrero_icon.png" alt="">
                    </span>
                    <div class="steps-content">
                        <div class="subtitle">Inicio</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'quotations'}>
                    <span class="steps-marker">
                        <img src="images/febrero_icon.png" alt="">
                    </span>
                    <div class="steps-content">
                        <div class="subtitle">Precios</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'pickup'}>
                    <span class="steps-marker">
                        <img src="images/febrero_icon.png" alt="">
                    </span>
                    <div class="steps-content">
                        <div class="subtitle">Origen</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'delivery'}>
                    <span class="steps-marker">
                        <img src="images/febrero_icon.png" alt="">
                    </span>
                    <div class="steps-content">
                        <div class="subtitle">Destino</div>
                    </div>
                </li>
                <li class="steps-segment" class:is-active={step === 'guide'}>
                    <span class="steps-marker">
                        <img src="images/febrero_icon.png" alt="">
                    </span>
                    <div class="steps-content">
                        <div class="subtitle">Guía</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>

<!-- QUIOTATION -->
    <div class="columns">
        <div class="column is-6 is-offset-3">

            {#if step === 'zipcodes'}
                <div class="columns">
                    <div class="column">
                        <div class="center">
                            <div class="title">Códigos postales</div>
                        </div>
                        <div class="columns">
                            <div class="column">
                                <div class="columns">
                                    <div class="column">
                                        <SwitchQuotation bind:shipmentType={shipmentType} on:change={clearLocations} />
                                    </div>
                                </div>
                                <div class="columns">
                                    <div class="column">
                                        {#if shipmentType === 'national'}
                                            <LocationsZipcodesComponent bind:selected={pickup} label="Código postal de origen" />
                                            <LocationsZipcodesComponent bind:selected={delivery} label="Código postal de destino" />
                                        {/if}
                                        {#if shipmentType === 'international'}
                                            <ZipcodesInternational bind:location={pickup} changeCountry={false} label="Origen" />
                                            <ZipcodesInternational bind:location={delivery} label="Destino" />
                                        {/if}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="columns">
                    <div class="column">
                        <div class="center">
                            <div class="title">Paquete</div>
                        </div>
                        <PackagesSelectInputsComponent bind:ipackage={ipackage} on:selected={onPackageSelected} />
                    </div>
                </div>
            {/if}
        
            {#if step === 'quotations'}
                <QuotationsComponent {pickup} {delivery} {ipackage} {localServices} {shipmentType} bind:quotation={quotation} on:selected={onQuotationSelected} />
            {/if}
        
            {#if step === 'pickup'}
                <LocationsComponent {step} disabled={true} showSelected={false} bind:location={pickup} on:selected={onPickupSelected} />
            {/if}
        
            {#if step === 'delivery'}
                <LocationsComponent {step} {shipmentType} disabled={true} showSelected={false} bind:location={delivery} on:selected={onDeliverySelected} />
            {/if}
        
            {#if step === 'guide'}
                <ShipmentComponent {pickup} {delivery} {ipackage} {quotation} {shipmentType} on:step={setStep} />
            {/if}
        </div>
    </div>
    <br>
</Container>
<br>
<br>
<br>
<br>
<br>

<VolumetricPopup />
<ValuePopup />
<FrequencyPopup />
<IdentifierPopup />