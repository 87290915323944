<script>

    import UsersLoginComponent from './component.login.svelte'
    import RecoveryModal from './modal.recovery.svelte'

</script>

<style>

    .containerr {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('/images/febrero_background.jpg');
        width: 100vw;
        margin: 0;
        padding: 0;
    }

    .content {
        border: solid 2px whitesmoke;
        border-radius: 16px;
        min-width: 30vw;
        z-index: 5;
        background-color: white;
    }

    .head {
        background: linear-gradient(90deg, #EF718C, #E66985);
        /* background: linear-gradient(70deg, #4E3596, #E66985); */
        color: white;
        border-radius: 16px 16px 0 0;
        padding: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    img {
        max-width: 100px;
    }
 
    .title {
        color: #FCF1DF !important;
        margin: 0 !important;
    }

    .body {
        padding: 32px;
    }

    @media screen and (max-width: 420px) {
        .container {
            align-items: flex-start;
        }
        .content {
            width: 100vw;
            border: none;
            border-radius: none;
        }
        .head {
            border-radius: 0;
        }
    }

</style>

<RecoveryModal />

<div class="containerr">
    <div class="content">
        <div class="head">
            <div class="title">MyShipper</div>
            <img src="images/brand_white.png" alt="MyShipper">
        </div>
        <div class="body">
            <UsersLoginComponent />
            <br>
            <div class="items-center">
                <a href="https://myshipper.com.mx/contacto/" target="_blank">¿Aún no estas registrado? Solicita información aquí.</a>
            </div>
            <br>
            <div class="items-end">
                <small><a href="files/aviso.pdf" target="_blank">Aviso de privacidad</a></small>
            </div>
        </div>
    </div>
</div>