<script>
	  
	export let loading = false
	  
</script>

<style>
    img {
        max-width: 250px;
        /* border-radius: 1000px;
        overflow: hidden; */
    }
</style>

{#if loading}
    <!-- <div class="items-center">
        <div class="animate__animated animate__flipInY animate__infinite">
            <img src="/images/brand_red.png" alt="MyShipper">
        </div>
    </div> -->
    <div class="items-center">
        <img src="/images/febrero_loading.gif" alt="Cargando" style="border-radius: 16px;">
        <!-- <div class="animate__animated animate__flipInY animate__infinite">
        </div> -->
    </div>
{/if}